import maskInput from 'vanilla-text-mask/dist/vanillaTextMask';
import checkoutValidation from './checkout-validation';
import Swiper from 'swiper';

document.addEventListener('DOMContentLoaded', () => {
  let sPickupsPaymentTypes = '';

  const phoneMask = ['+', '7', ' ', '(', /[9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
  const phoneInput = document.querySelector('.js-phone-input');

  if (phoneInput) {
    maskInput({
      inputElement: phoneInput,
      mask: phoneMask
    });
  }
  
  const checkout = document.querySelector('.checkout');
  if (checkout) {
    window.checkout.setNewAddress = () => setAddressSelect();
    window.checkout.resetPoint = (pointType) => resetPoint(pointType);
    window.checkout.setAddressData = (addressId) => setAddressData(addressId);
    window.checkout.updateCheckoutWindowData = (country, region, cityObject) => updateCheckoutWindowData(country, region, cityObject);
    window.checkout.updateDeliveryPrice = (type, price) => updateDeliveryPrice(type, price);
    window.checkout.showPreloader = () => checkoutPreloader(true);
    window.checkout.hidePreloader = () => checkoutPreloader(false);
    if (!window.checkout.selectedValues) {
      window.checkout.selectedValues = {
        user: {
          profileId: "",
          name: "",
          lastName: "",
          phone: "",
          email: "",
          address: {
            city: {id: null, itemId: null, text: ''},
            street: {id: null, itemId: null, text: ''},
            house: {id: null, itemId: null, text: ''},
            flat: ""
          }
        },
        delivery: {
          deliveryId: "",
          pickupId: "",
          shopId: "",
          price: ""
        },
        paymentSystem: {
          id: "",
          sum: "",
          url: ""
        },
        comment: ''
      }
    }

    if (!window.checkout.paySystem) {
      window.checkout.paySystem = {
        checked: '',
        items: [
          {
            id: "6",
            name: "Онлайн оплата",
            url: "url"
          },
          {
            id: "14",
            name: "Оплата при получении",
            url: "url"
          }
        ]
      }
    }

    checkoutValidation();
    getFieldValues();
    resetAddressInputs();
    changeUserFields();

    const container = checkout.querySelector('#js-select');
    const selectText = checkout.querySelector('#js-select-open');
    const deliveryContent = checkout.querySelector('[data-content=\'delivery\']');
    const addressContent = checkout.querySelector('[data-content=\'delivery-address\']');
    const pointData = deliveryContent.querySelector("[data-content='delivery-pickup']");
    const shopData = deliveryContent.querySelector("[data-content='delivery-shop']");

    checkout.addEventListener('click', ({target}) => {
      const selectOpen = target.closest('#js-select-open');
      const selectContainer = target.closest('#js-select');

      if (selectOpen) {
        if (container.hasAttribute('data-open')) {
          container.removeAttribute('data-open');
        } else {
          container.setAttribute('data-open', '');
        }
      }

      if (selectContainer) {
        const button = target.closest('[data-select-id]');

        if (button) {
          selectContainer.removeAttribute('data-open');
          const selectId = button.getAttribute('data-select-id');

          setSelectText(selectId);
          setAddressData(selectId);
        }
      } else {
        container.removeAttribute('data-open');
      }
    });

    if (window.checkout && window.checkout.user) {
      const firstNameInput = document.querySelector('input#ch-first-name');
      const lastNameInput = document.querySelector('input#ch-last-name');
      const phoneInput = document.querySelector('input#ch-phone');
      const emailInput = document.querySelector('input#ch-email');

      for (const [key, value] of Object.entries(window.checkout.user)) {
        switch (key) {
          case 'firstName':
            firstNameInput.value = value;
            window.checkout.selectedValues.user.name = value;
            break;
          case 'lastName':
            lastNameInput.value = value;
            window.checkout.selectedValues.user.lastName = value;
            break;
          case 'registerPhone':
            phoneInput.value = value;
            window.checkout.selectedValues.user.phone = value;
            break;
          case 'email':
            emailInput.value = value;
            window.checkout.selectedValues.user.email = value;
            break;
        }
      }
    }

    if (window.checkout && window.checkout.address.default.city){
      setDefaultCity();
    }

    if (window.checkout && window.checkout.delivery) {
      if (window.checkout.delivery.items) {
        setAvailableDeliveries(window.checkout.delivery.items)
      }

      if (window.checkout.delivery.checked && window.checkout.delivery.checked.length) {
        const radioInput = deliveryContent.querySelector(`input#ch-d-${window.checkout.delivery.checked}`);
        radioInput.checked = true;

        switch (window.checkout.delivery.checked) {
          case 'pickup':
            addressContent.classList.add('m-hidden');
            shopData.classList.add('m-hidden');
            break;
          case 'shop':
            addressContent.classList.add('m-hidden');
            pointData.classList.add('m-hidden');
            break;
          default:
            addressContent.classList.remove('m-hidden');
            pointData.classList.add('m-hidden');
            shopData.classList.add('m-hidden');
            break;
        }

        window.checkout.selectedValues.delivery.deliveryId = window.checkout.delivery.items[window.checkout.delivery.checked].id;
        window.checkout.selectedValues.delivery.price = window.checkout.delivery.items[window.checkout.delivery.checked].price;
      }
    }

    const addressOnLoad = selectText.getAttribute('data-selected-id')
    const isAddressEmpty = addressOnLoad === 'empty' || addressOnLoad === '';
    const isWindowAddressEmpty = window.checkout.address.checked ? window.checkout.address.checked === 'empty' : true;

    if (!isAddressEmpty && addressOnLoad) {
      setSelectText(addressOnLoad);
      setAddressData(addressOnLoad);
    } else if (!isWindowAddressEmpty && window.checkout.address.checked.length) {
      setSelectText(window.checkout.address.checked)
      setAddressData(window.checkout.address.checked)
    }

    const radioDeliveries = deliveryContent.querySelector('.ds-ch-radio-deliveries');
    const allDeliveriesRadio = radioDeliveries.querySelectorAll('input.radio');
    allDeliveriesRadio.forEach(deliveryRadioBtn => {
      changeDeliveryEvent(deliveryRadioBtn);

      const deliveryRadioBtnType = deliveryRadioBtn.id.slice(5);

      if (deliveryRadioBtn.checked) {
        switch (deliveryRadioBtnType) {
          case 'pickup':
            addressContent.classList.add('m-hidden');
            shopData.classList.add('m-hidden');
            break;
          case 'shop':
            addressContent.classList.add('m-hidden');
            pointData.classList.add('m-hidden');
            break;
          default:
            addressContent.classList.remove('m-hidden');
            pointData.classList.add('m-hidden');
            shopData.classList.add('m-hidden');
            break;
        }
        setAvailablePayment(deliveryRadioBtn);
        if (deliveryRadioBtnType !== 'empty'){
          window.checkout.selectedValues.delivery.deliveryId = window.checkout.delivery.items[deliveryRadioBtnType].id
        }
      }
    });

    const addressDataInputs = addressContent.querySelectorAll('.input');
    const city = deliveryContent.querySelector('.input#ch-city');
    const deliveryInputs = [...addressDataInputs, city];
    
    deliveryInputs.forEach(input => {
      if (input) {
        input.addEventListener('change', () => {
          setAddressSelect();
        });
      }
    });

    const paymentContent = checkout.querySelector('[data-content="checkout-payment"]')
    const allPayments = paymentContent.querySelectorAll('input.radio');

    allPayments.forEach(paymentRadio => {
      const paymentData = window.checkout.paySystem.items.find(({id}) => id === paymentRadio.parentElement.getAttribute('data-id'));

      paymentRadio.addEventListener('change', () => {
        window.checkout.selectedValues.paymentSystem.id = paymentData ? paymentData.id : '';
        window.checkout.selectedValues.paymentSystem.url = paymentData ? paymentData.url : '';

        const selectedAddress = window.checkout.address.items.find(({id}) => id == window.checkout.address.checked);

        let countryForDelivery = window.dadataAddress.city.data.country || window.checkout.address.default.country,
          regionForDelivery = window.dadataAddress.city.data.region || window.checkout.address.default.region,
          cityForDelivery = window.dadataAddress.city.text || window.checkout.address.default.city,
          cityFiasId = 
            window.dadataAddress.city.data.city_fias_id 
            || window.dadataAddress.city.data.settlement_fias_id 
            || window.dadataAddress.city.data.area_fias_id 
            || window.dadataAddress.city.data.region_fias_id 
            || window.checkout.address.default.cityFiasId,
            cityZip = window.dadataAddress.city.data.postal_code ? window.dadataAddress.city.data.postal_code : window.checkout.selectedValues.user.address.zip;
        
        if (selectedAddress) {
          countryForDelivery = selectedAddress.delivery.country || countryForDelivery;
          regionForDelivery = selectedAddress.delivery.region || regionForDelivery;
          cityForDelivery = selectedAddress.delivery.city || cityForDelivery;
          cityFiasId = selectedAddress.delivery.fias || cityFiasId;
          cityZip = selectedAddress.delivery.zip || cityZip;
        }

        cityForDelivery = window.checkout.city.checked || cityForDelivery;

        updateCheckoutWindowData(countryForDelivery, regionForDelivery, {name: cityForDelivery, fias: cityFiasId, zip: cityZip}, false, false);
        window.checkout.deletePaymentError();
        getRecalculatedBasket(window.checkout.selectedValues.paymentSystem.id);
      })

      if (paymentRadio.checked) {
        window.checkout.selectedValues.paymentSystem.id = paymentData ? paymentData.id : '';
        window.checkout.selectedValues.paymentSystem.url = paymentData ? paymentData.url : '';
      }
    })

    setFirstDeliveryOrPayment('delivery');
  }

  if (window.innerWidth >= 1280) {
    let itemsSliders = document.getElementsByClassName('swiper-profile');

    Array.from(itemsSliders).forEach(function (slider) {
      const allSlides = slider.querySelectorAll('.swiper-slide');

      if (allSlides.length > 4) {
        let checkoutSlider = new Swiper(slider, {
          slidesPerView: 4,
          spaceBetween: 20,
          navigation: {
            nextEl: ".swiper-next",
            prevEl: ".swiper-prev"
          }
        });
        let buttonLeft = document.getElementsByClassName('swiper-prev')[0];
        let buttonRight = document.getElementsByClassName('swiper-next')[0];
        checkoutSlider.on('slideChange', function () {
          if (checkoutSlider.activeIndex == 0) {
            slider.style.marginLeft = 0;
            buttonRight.style.right = '10px';
          } else if (checkoutSlider.activeIndex == checkoutSlider.slides.length-4) {
            slider.style.marginRight = 0;
            buttonLeft.style.left = '10px';
          } else {
            slider.style.marginLeft = 'auto';
            slider.style.marginRight = 'auto';
            buttonLeft.style.left = 0;
            buttonRight.style.right = 0;
          }
        });
        buttonLeft.style.display = "block";
        buttonRight.style.display = "block";
      } else {
        let profileList = slider.querySelector('.profile__list');
        profileList.classList.add('select__list');
        slider.style.width = '100%';
      }
    });
  }

  function getFieldValues() {
    const contactInputs = ['ch-first-name', 'ch-last-name', 'ch-phone', 'ch-email'];
    const userContent = document.querySelector("[data-content='user']");
    let userInputs = [];
    contactInputs.forEach(inputName =>{
      userInputs.push(userContent.querySelector(`input#${inputName}`))
    })
    const commentField = document.getElementById('ch-comment');
    window.checkout.selectedValues.comment = commentField.value;

    userInputs.forEach(field => {
      let type = 'name';
      switch (field.id) {
        case 'ch-last-name':
          type = 'lastName';
          break;
        case 'ch-phone':
          type = 'phone';
          break;
        case 'ch-email':
          type = 'email';
          break;
      }
      window.checkout.selectedValues.user[type] = field.value;
    })
  }

  function resetAddressInputs() {
    const deliveryInputs = ['ch-city', 'ch-street', 'ch-house', 'ch-flat'];
    let addressInputs = [];
    deliveryInputs.forEach(inputName =>{
      addressInputs.push(document.querySelector(`input#${inputName}`))
    })
    addressInputs.forEach(input =>{
      input.value = ""
    })
  }

  function changeDeliveryEvent(deliveryRadioBtn) {
    deliveryRadioBtn.addEventListener('change', () => {
      const deliveryContent = checkout.querySelector('[data-content=\'delivery\']');
      const radioDeliveries = deliveryContent.querySelector('.ds-ch-radio-deliveries');
      const addressContent = checkout.querySelector('[data-content=\'delivery-address\']');
      const pointData = deliveryContent.querySelector("[data-content='delivery-pickup']");
      const shopData = deliveryContent.querySelector("[data-content='delivery-shop']");
      const deliveryType = deliveryRadioBtn.id.slice(5);
      const deliveryPrice = deliveryRadioBtn.parentElement.querySelector('.ds-delivery-price').textContent;

      setAddressSelect();

      const errorElements = radioDeliveries.querySelectorAll('.ch-order__error');

      errorElements.forEach(oError => {
        oError.remove();
      });

      switch (deliveryType) {
        case 'pickup':
          addressContent.classList.add('m-hidden');
          shopData.classList.add('m-hidden');

          if (window.checkout && window.checkout.selectedPickup && window.checkout.selectedPickup.id) {
            deliveryRadioBtn.setAttribute('data-payment', window.checkout.selectedPickup.paymentType);
            pointData.classList.remove('m-hidden');
          } else {
            const buttonSelect = deliveryRadioBtn.parentElement.querySelector('button');
            
            buttonSelect.classList.remove('m-hidden');
          }
          break;
        case 'shop':
          addressContent.classList.add('m-hidden');
          pointData.classList.add('m-hidden');
          if (window.checkout && window.checkout.selectedShop && window.checkout.selectedShop.id) {
            shopData.classList.remove('m-hidden');
          } else {
            const buttonSelect = deliveryRadioBtn.parentElement.querySelector('button');
            buttonSelect.classList.remove('m-hidden');
          }
          break;
        default:
          addressContent.classList.remove('m-hidden');
          pointData.classList.add('m-hidden');
          shopData.classList.add('m-hidden');
          break;
      }

      setAvailablePayment(deliveryRadioBtn);
      updateCheckoutDeliveryPrice(deliveryPrice);
      window.checkout.selectedValues.delivery.deliveryId = window.checkout.delivery.items[deliveryType].id;
      window.checkout.selectedValues.delivery.price = deliveryPrice;

      setFirstDeliveryOrPayment('payment');
    });
  }

  function changeUserFields() {
    const userContent = document.querySelector("[data-content='user']");
    const firstName = userContent.querySelector('input#ch-first-name');
    const lastName = userContent.querySelector('input#ch-last-name');
    const phone = userContent.querySelector('input#ch-phone');
    const email = userContent.querySelector('input#ch-email');
    const userInputs = [firstName, lastName, phone, email];

    userInputs.forEach(field => {
      field.addEventListener('input', () => {
        let type = 'name';
        switch (field.id) {
          case 'ch-last-name':
            type = 'lastName';
            break;
          case 'ch-phone':
            type = 'phone';
            break;
          case 'ch-email':
            type = 'email';
            break;
        }
        window.checkout.selectedValues.user[type] = field.value;
      })
    })

    const commentField = document.getElementById('ch-comment');
    commentField.addEventListener('input', () => {
      window.checkout.selectedValues.comment = commentField.value;
    })
  }

  function setSelectText(selectAddressId) {
    const container = checkout.querySelector('#js-select');
    const selectText = checkout.querySelector('#js-select-open');

    const allButtons = container.querySelectorAll('[data-select-id]');
    const buttonSelected = container.querySelector(`[data-select-id='${selectAddressId}']`);

    allButtons.forEach(btn => btn.classList.remove('m-selected'));
    if (buttonSelected) {
      buttonSelected.classList.add('m-selected');
      selectText.innerHTML = buttonSelected.innerHTML;
      selectText.setAttribute('data-selected-id', selectAddressId);
    }
  }

  function setAddressData(addressId) {
    const selectedAddress = window.checkout.address.items.find(({id}) => id === addressId);
    const userContent = document.querySelector("[data-content='user']");
    const deliveryContent = document.querySelector("[data-content='delivery']");
    const addressContent = document.querySelector("[data-content='delivery-address']");
    const city = deliveryContent.querySelector('input#ch-city');
    const street = deliveryContent.querySelector('input#ch-street');
    const house = deliveryContent.querySelector('input#ch-house');
    const flat = deliveryContent.querySelector('input#ch-flat');
    const addressInputs = [city, street, house, flat];
    const radioDeliveries = deliveryContent.querySelector('.ds-ch-radio-deliveries');
    const pointData = deliveryContent.querySelector("[data-content='delivery-pickup']");
    const shopData = deliveryContent.querySelector("[data-content='delivery-shop']");


    if (selectedAddress) {
      const firstName = userContent.querySelector('input#ch-first-name');
      const lastName = userContent.querySelector('input#ch-last-name');
      const phone = userContent.querySelector('input#ch-phone');
      const email = userContent.querySelector('input#ch-email');
      const userInputs = [firstName, lastName, phone, email];

      userInputs.forEach(input => {
        input.value = '';
        removeError(input);
      });

      window.checkout.selectedValues.user = {
        profileId: selectedAddress.id,
        name: "",
        lastName: "",
        phone: "",
        email: "",
        address: {
          city: {id: null, itemId: null, text: ''},
          street: {id: null, itemId: null, text: ''},
          house: {id: null, itemId: null, text: ''},
          flat: "",
          fias: "",
          zip: ""
        }
      }

      addressInputs.forEach(input => {
        input.value = '';
        removeError(input);
      });

      resetPoint('pickup');
      resetPoint('shop');

      window.checkout.selectedValues.delivery = {
        deliveryId: "",
        pickupId: "",
        shopId: "",
        price: ""
      }

      resetNodeErrors(radioDeliveries);

      if (selectedAddress.delivery.city !== window.checkout.city.checked) {
        window.checkout.city.checked = selectedAddress.delivery.city;
        updateCheckoutOnProfileSelect(selectedAddress.delivery.country, selectedAddress.delivery.region, {name: selectedAddress.delivery.city, fias: selectedAddress.delivery.fias, zip: selectedAddress.delivery.zip}, false)
         .then(() => {
           if (selectedAddress.delivery) {
             setDeliveryData();
           }
           checkoutPreloader(false);
         })
         .catch(error => console.error(error));
      } else {
        if (selectedAddress.delivery) {
          setDeliveryData();
        }
      }

      if (selectedAddress.user) {
        for (const [key, value] of Object.entries(selectedAddress.user)) {
          switch (key) {
            case 'firstName':
              firstName.value = value;
              break;
            case 'lastName':
              lastName.value = value;
              break;
            case 'phone':
              phone.value = value;
              break;
            case 'email':
              email.value = value;
              break;
          }
        }

        window.checkout.selectedValues.user.name = selectedAddress.user.firstName ? selectedAddress.user.firstName : '';
        window.checkout.selectedValues.user.lastName = selectedAddress.user.lastName ? selectedAddress.user.lastName : '';
        window.checkout.selectedValues.user.phone = selectedAddress.user.phone ? selectedAddress.user.phone : '';
        window.checkout.selectedValues.user.email = selectedAddress.user.email ? selectedAddress.user.email : '';
      }
    }

    if (!selectedAddress && addressId === 'empty') {
      addressInputs.forEach(input => {
        input.value = '';
        removeError(input);
      });

      resetNodeErrors(radioDeliveries);

      window.checkout.selectedValues.user.profileId = "";
      window.checkout.selectedValues.user.address = {
        city: {id: null, itemId: null, text: ''},
        street: {id: null, itemId: null, text: ''},
        house: {id: null, itemId: null, text: ''},
        flat: "",
        fias: "",
        zip: ""
      };
      window.checkout.selectedValues.delivery = {
        deliveryId: "",
        pickupId: "",
        shopId: "",
        price: ""
      }

      setDefaultCity();

      if (window.checkout.city.checked !== window.checkout.address.default.city) {
        window.checkout.city.checked = window.checkout.address.default.city;
        updateCheckoutWindowData(window.checkout.address.default.country, window.checkout.address.default.region, {name: window.checkout.address.default.city, fias: window.checkout.address.default.fias, zip: ''});
      }

      resetPoint('pickup');
      resetPoint('shop');
      const radioUnselect = radioDeliveries.querySelector('.radio#ch-d-empty');
      radioUnselect.checked = true;

      updateCheckoutDeliveryPrice('0 ₽');
    }

    function setDeliveryData() {
      const radioInput = deliveryContent.querySelector(`input#ch-d-${selectedAddress.delivery.type}`);
      const buttonSelect = radioInput.parentElement.querySelector('button');
      const deliveryPrice = radioInput.parentElement.querySelector('.ds-delivery-price').textContent;
      radioInput.checked = true;

      switch (selectedAddress.delivery.type) {
        case 'pickup':
          addressContent.classList.add('m-hidden');
          shopData.classList.add('m-hidden');
          selectPoint(selectedAddress.delivery.id, 'pickup', buttonSelect);
          break;
        case 'shop':
          addressContent.classList.add('m-hidden');
          pointData.classList.add('m-hidden');
          selectPoint(selectedAddress.delivery.id, 'shop', buttonSelect);
          break;
        default:
          addressContent.classList.remove('m-hidden');
          pointData.classList.add('m-hidden');
          shopData.classList.add('m-hidden');
          break;
      }

      setAddressInput(selectedAddress.delivery);

      if (radioInput.hasAttribute("data-payment")) {
        const inputPaymentIds = radioInput.getAttribute("data-payment").split('_');
        checkPayment(inputPaymentIds);
      }

      updateCheckoutDeliveryPrice(deliveryPrice);

      window.checkout.selectedValues.delivery.deliveryId = window.checkout.delivery.items[selectedAddress.delivery.type].id;
      window.checkout.selectedValues.delivery.price = deliveryPrice
      window.checkout.selectedValues.user.address.fias = selectedAddress.delivery.fias ? selectedAddress.delivery.fias : "";
      window.checkout.selectedValues.user.address.zip = selectedAddress.delivery.zip ? selectedAddress.delivery.zip : "";

      setFirstDeliveryOrPayment('payment');
    }

    function removeError(inputNode) {
      if (inputNode.classList.contains('error')) {
        inputNode.classList.remove('error');
        const errorNode = inputNode.nextElementSibling;
        inputNode.parentElement.removeChild(errorNode);
      }
    }

    function setAddressInput(address) {
      const citiesPopupOpen = document.querySelectorAll('.ds-popup-cities-open');

      for (const [key, value] of Object.entries(address)) {
        let newAddressObject = {
          id: null,
          itemId: value,
          name: value,
          text: value
        }
        switch (key) {
          case 'city':
            city.value = value;

            citiesPopupOpen.forEach(openButton => {
              openButton.innerHTML = 'г. ' + value;
            })

            if (address.region) {
              newAddressObject.data = {
                region: address.region
              }
            }

            if (address.fias)
              newAddressObject.data.city_fias_id = address.fias

            window.dadataAddress.city = newAddressObject;
            window.dadata.setItem('city', newAddressObject);
            window.checkout.selectedValues.user.address.city = newAddressObject;
            break;
          case 'street':
            street.value = value;
            window.dadataAddress.street = newAddressObject;
            window.dadata.setItem('street', newAddressObject);
            window.checkout.selectedValues.user.address.street = newAddressObject;
            break;
          case 'house':
            house.value = value;
            window.dadataAddress.house = newAddressObject;
            window.dadata.setItem('house', newAddressObject);
            window.checkout.selectedValues.user.address.house = newAddressObject;
            break;
          case 'flat':
            flat.value = value;
            window.dadataAddress.flat = value;
            window.dadata.setItem('flat', value);
            window.checkout.selectedValues.user.address.flat = value;
            break;
        }
      }
    }
  }

  function setDefaultCity(){
    const citiesPopupOpen = document.querySelectorAll('.ds-popup-cities-open');

    citiesPopupOpen.forEach(openButton => {
      openButton.innerHTML = 'г. ' + window.checkout.address.default.city;
    })

    const cityInput = document.querySelector('input#ch-city');

    cityInput.value = window.checkout.address.default.city;

    let newAddressObject = {
      id: null,
      itemId: window.checkout.address.default.city,
      name: window.checkout.address.default.city,
      text: window.checkout.address.default.city
    }

    if (window.checkout.address.default.region) {
      newAddressObject.data = {
        region: window.checkout.address.default.region,
        postal_code: window.checkout.address.default.postalCode,
        city_fias_id: window.checkout.address.default.cityFiasId
      }
    }

    window.dadataAddress.city = newAddressObject;
    window.dadata.setItem('city', newAddressObject);
    window.checkout.selectedValues.user.address.city = newAddressObject;
  }

  function selectPoint(pointId, type, buttonSelect) {
    const deliveryContent = document.querySelector("[data-content='delivery']");
    const pointData = deliveryContent.querySelector(`[data-content='delivery-${type}']`);
    const pointTitle = pointData.querySelector(`[data-content='${type}-title']`);
    const pointAddress = pointData.querySelector(`[data-content='${type}-address']`);
    const paymentInput = document.querySelector("input#ch-d-".concat(type));
    let point = null;

    if (type === 'pickup') {
      point = window.checkout.pickupPoints.find(({id}) => id.toString() == pointId.toString());
      paymentInput.setAttribute('data-payment', point.paymentType);
    } else {
      point = window.checkout.shopsPoints.find(({storeXmlId}) => storeXmlId.toString() == pointId.toString());
    }

    if (point) {
      pointTitle.innerHTML = point.name;
      pointAddress.innerHTML = point.address;
      pointData.classList.remove('m-hidden');

      if (type === 'pickup') {
        window.checkout.selectedPickup = point;
        window.checkout.selectedValues.delivery.pickupId = point.id;
      } else {
        window.checkout.selectedShop = point;
        window.checkout.selectedValues.delivery.shopId = point.id;
      }

      const popup = document.querySelector(`.ds-popup-${type}`);
      const selectedPoint = popup.querySelector(`.ds-popup-${type}-point[data-id="${pointId}"]`);

      popup.querySelectorAll(`.ds-popup-${type}-point`).forEach(pointItem => {
        pointItem.removeAttribute('data-active');
      });

      selectedPoint.setAttribute('data-active', '');
      buttonSelect.classList.add('m-hidden');
    }
  }

  function resetPoint(pointType) {
    const pointData = document.querySelector(`[data-content='delivery-${pointType}']`);
    const pointTitle = pointData.querySelector(`[data-content='${pointType}-title']`);
    const pointAddress = pointData.querySelector(`[data-content='${pointType}-address']`);
    pointTitle.innerHTML = '';
    pointAddress.innerHTML = '';
    pointData.classList.add('m-hidden');

    if (pointType === 'pickup') {
      window.checkout.selectedPickup = null;
    } else {
      window.checkout.selectedShop = null;
    }

    const deliveryRadioSelect = document.querySelector(`.ds-popup-${pointType}-open`)

    deliveryRadioSelect.classList.remove('m-hidden');

    const popup = document.querySelector(`.ds-popup-${pointType}`);
    
    popup.querySelectorAll(`.ds-popup-${pointType}-point`).forEach(pointItem => {
      pointItem.removeAttribute('data-active');
    });
  }

  function setAddressSelect() {
    const selectText = document.querySelector('#js-select-open');
    const selectContainer = document.querySelector('#js-select');
    const selectId = selectText.getAttribute('data-selected-id');
    const addressEmpty = selectContainer.querySelector('[data-select-id=\'empty\']');
    const street = document.querySelector('input#ch-street');

    if (selectId && selectId !== 'empty') {
      const allButtons = selectContainer.querySelectorAll('[data-select-id]');
      
      allButtons.forEach(btn => btn.classList.remove('m-selected'));

      addressEmpty.classList.add('m-selected');
      selectText.innerHTML = addressEmpty.innerHTML;
      selectText.setAttribute('data-selected-id', 'empty');
      window.checkout.selectedValues.user.profileId = '';
      street.value = '';
      
      for(let arAddress of window.checkout.address.items)
      {
        if(selectId == arAddress.id)
        {
          if (arAddress.delivery.zip && !empty(arAddress.delivery.zip))
            window.checkout.address.default.postalCode = arAddress.delivery.zip;

          break;
        }
      }
    }
  }

  function setAvailableDeliveries(deliveriesList) {
    const radioDeliveries = document.querySelector('.ds-ch-radio-deliveries');
    let allTypes = [];
    let checkedTypes = [];

    for (const [type, delivery] of Object.entries(deliveriesList)) {
      let currentNode = radioDeliveries.querySelector(`input#ch-d-${type}`) ? radioDeliveries.querySelector(`input#ch-d-${type}`).parentElement : null;
      allTypes.push(type);

      if (!delivery.type) {
        delivery.type = type;
      }

      if (delivery.available) {
        setDeliveryData(currentNode, delivery)
      } else {
        hideDelivery(currentNode)
      }
    }

    const allDeliveries = radioDeliveries.querySelectorAll(`input`);

    allDeliveries.forEach(deliveryItem => {
      const curType = deliveryItem.id.slice(5);

      checkedTypes.push(curType);
    });

    const hiddenTypes = checkedTypes.filter(el => !allTypes.includes(el));

    hiddenTypes.forEach(type => {
      let currentNode = radioDeliveries.querySelector(`input#ch-d-${type}`) ? radioDeliveries.querySelector(`input#ch-d-${type}`).parentElement : null;
      hideDelivery(currentNode)
    });

    const allRadio = radioDeliveries.querySelectorAll('input.radio');

    allRadio.forEach(radioInput => {
      if (radioInput.checked) {
        let radio = radioInput;
        if (radioInput.parentElement.classList.contains('m-hidden')) {
          const emptyRadio = radioDeliveries.querySelector('input#ch-d-empty');
          if (emptyRadio) {
            emptyRadio.checked = true;
            radio = emptyRadio;
          }
        }

        const radioInputWrap = radioInput.closest('li.ch-order-radio');
        const deliveryContent = checkout.querySelector('[data-content=\'delivery\']');
        const addressContent = checkout.querySelector('[data-content=\'delivery-address\']');
        const addressContentWrap = addressContent.closest('li.ch-order__address-wrap');
        const pointData = deliveryContent.querySelector("[data-content='delivery-pickup']");
        const shopData = deliveryContent.querySelector("[data-content='delivery-shop']");

        switch (radio.id.slice(5)) {
          case "pickup":
            addressContent.classList.add('m-hidden');
            shopData.classList.add('m-hidden');
            break;
          case "shop":
            addressContent.classList.add('m-hidden');
            pointData.classList.add('m-hidden');

            if (window.site.isElisSite && window.site.isProd)
              ym(window.site.iYandexTrackerId, 'reachGoal', 'delivery_from_shop');

            break;
          default:
            addressContentWrap.style.order = radioInputWrap.style.order;
            addressContent.classList.remove('m-hidden');
            pointData.classList.add('m-hidden');
            shopData.classList.add('m-hidden');
            break;
        }
      }
    })

    updatePrice();

    function setDeliveryData(itemNode, itemData) {
      if (itemNode && itemData) {
        itemNode.classList.remove('m-hidden');
        const input = itemNode.querySelector('input');
        const text = itemNode.querySelector('.ds-delivery-text');
        const time = itemNode.querySelector('.ds-delivery-time');
        const price = itemNode.querySelector('.ds-delivery-price');
        let paymentsId = (itemData.type === 'pickup' && sPickupsPaymentTypes.length > 0 ? sPickupsPaymentTypes : itemData.paySystem.join('_'));

        if (itemData.type === 'pickup' && !empty(window.checkout.selectedPickup)) {
          paymentsId = window.checkout.selectedPickup.paymentType;

          if (itemData.price != 0 && window.checkout.selectedPickup.price) {
            itemData.price = window.checkout.selectedPickup.price;
            itemData.priceFormatted = window.checkout.selectedPickup.priceFormatted;
          }
        }

        input.setAttribute('data-payment', paymentsId);

        if (itemData.price || itemData.priceFormatted && price) {
          price.innerHTML = '';
          price.innerHTML = itemData.priceFormatted ? itemData.priceFormatted : itemData.price;
        }

        if (itemData.description && text) {
          text.innerHTML = '';
          text.innerHTML = itemData.description;
        }

        if (itemData.period && time) {
          time.innerHTML = '';
          time.innerHTML = `(${itemData.period})`;
        }
      } else if (itemData) {
        const paymentsId = (itemData.type === 'pickup' && sPickupsPaymentTypes.length > 0 ? sPickupsPaymentTypes : itemData.paySystem.join('_'));
        const newDelivery = document.createElement('li');

        newDelivery.classList.add('ch-order-radio');

        const newDeliveryPrice = itemData.priceFormatted ? itemData.priceFormatted : itemData.price;

        newDelivery.innerHTML =
           `
                        <input class="radio" type="radio" name="delivery" id="ch-d-${itemData.type}" data-payment="${paymentsId}">
                        <label class="field-label ch-order-radio__label" for="ch-d-${itemData.type}">${itemData.name ? itemData.name : ''}</label>
                        <div class="ch-order-radio__info">
                          <p class="ch-order-radio__text ds-delivery-text">${itemData.description ? itemData.description : ''}</p>
                          <p class="ch-order-radio__price">
                            <span class="ds-delivery-price">${newDeliveryPrice ? newDeliveryPrice : ''}</span>
                            <span class="ds-delivery-time">${itemData.period ? itemData.period : ''}</span>
                          </p>
                        </div>
        `
        const deliveryErrorNode = radioDeliveries.querySelector('.ch-order__error');
        if (deliveryErrorNode) {
          deliveryErrorNode.before(newDelivery);
        } else {
          radioDeliveries.appendChild(newDelivery);
        }

        changeDeliveryEvent(newDelivery.querySelector('input'));
      }
    }

    function hideDelivery(itemNode) {
      if (itemNode) {
        itemNode.classList.add('m-hidden');
      }
    }

    function updatePrice() {
      const radioItems = radioDeliveries.querySelectorAll('input');
      let selectedRadioItem = null;
      radioItems.forEach(item => {
        if (item.checked) {
          selectedRadioItem = item;
        }
      })
      if (selectedRadioItem) {
        const priceNode = selectedRadioItem.parentElement.querySelector('.ds-delivery-price');
        if (priceNode) {
          const deliveryPrice = priceNode.textContent;
          updateCheckoutDeliveryPrice(deliveryPrice);
        } else {
          updateCheckoutDeliveryPrice("0 ₽");
        }
      }
    }
  }

  function getAvailableDeliveries(regionData, cityData, fias = '', zip = '') {
    const region = regionData ? regionData : '';
    const city = cityData ? cityData : '';
    const paymentSystemId = window.checkout.selectedValues.paymentSystem.id || 6;

    if (window.BX) {
      return window.BX.ajax.runComponentAction('ega:order', 'getDeliveryList',
         {
           mode: 'class',
           data: {
             region,
             city,
             paymentSystemId,
             fias,
             zip
           },
         })
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            status: 'success',
            data: {
              success: true,
              deliveries: {
                courier: {
                  id: "20",
                  available: true,
                  period: "4-6 дня",
                  priceFormatted: "350 ₽",
                  price: "350",
                  text: "Доставим домой в удобное время.",
                  paySystem: ['6', '14']
                },
                pickup: {
                  id: "19",
                  available: false,
                  period: "4-6 дня",
                  priceFormatted: "402 ₽",
                  price: "402",
                  paySystem: ['6', '14']
                },
                shop: {
                  id: '2',
                  available: true,
                  period: 'От 7 дней',
                  priceFormatted: 'Бесплатно',
                  price: '0',
                  paySystem: ['6']
                },
                post: {
                  id: '2',
                  available: true,
                  period: 'От 8 дней',
                  priceFormatted: '500 ₽',
                  price: '500',
                  description: "Доставим почтой",
                  name: 'Почтой России',
                  paySystem: ['6']
                }
              },
              checked: 'courier'
            }
          });
        }, 2000)

      });
    }
  }

  function setAvailablePayment(deliveryInput) {
    const availablePayments = deliveryInput.getAttribute('data-payment');
    if (availablePayments) {
      const paymentsId = availablePayments.split('_');
      checkPayment(paymentsId);
    }
  }

  function checkPayment(ids) {
    const paymentItems = document.querySelector('[data-content=\'checkout-payment\']');

    const onlineItemInput = paymentItems.querySelector('input#ch-p-online');
    const onlineItem = onlineItemInput ? onlineItemInput.parentElement : null;
    const onlineId = onlineItem ? onlineItem.getAttribute('data-id') : null;
    const isOnline = onlineId && ids.includes(onlineId);
    if (onlineItemInput) onlineItemInput.checked = false;

    const offlineItemInput = paymentItems.querySelector('input#ch-p-offline');
    const offlineItem = offlineItemInput ? offlineItemInput.parentElement : null;
    const offlineId = offlineItem ? offlineItem.getAttribute('data-id') : null;
    const isOffline = offlineId && ids.includes(offlineId);
    if (offlineItemInput) offlineItemInput.checked = false;

    const onlineSplitItemInput = paymentItems.querySelector('input#ch-p-onlineSplit');
    const onlineSplitItem = onlineSplitItemInput ? onlineSplitItemInput.parentElement : null;
    const onlineSplitId = onlineSplitItem ? onlineSplitItem.getAttribute('data-id') : null;
    const isOnlineSplit = onlineSplitId && ids.includes(onlineSplitId);
    if (onlineSplitItemInput) onlineSplitItemInput.checked = false;

    const onlineSbpItemInput = paymentItems.querySelector('input#ch-p-onlineSbp');
    const onlineSbpItem = onlineSbpItemInput ? onlineSbpItemInput.parentElement : null;
    const onlineSbpId = onlineSbpItem ? onlineSbpItem.getAttribute('data-id') : null;
    const isOnlineSbp = onlineSbpId && ids.includes(onlineSbpId);
    if (onlineSbpItemInput) onlineSbpItemInput.checked = false;

    if (onlineItem)
    {
      if (!isOnline)
      {
        onlineItem.classList.add('m-hidden');

        const payData = window.checkout.paySystem.items.find(({id}) => id === offlineId || id === onlineSplitId || id === onlineSbpId)

        window.checkout.selectedValues.paymentSystem.id = payData ? payData.id : '';
        window.checkout.selectedValues.paymentSystem.url = payData ? payData.url : '';
      }
      else
      {
        onlineItem.classList.remove('m-hidden');
      }
    }

    if (offlineItem)
    {
      if (!isOffline)
      {
        offlineItem.classList.add('m-hidden');

        const payData = window.checkout.paySystem.items.find(({id}) => id === onlineId || id === onlineSplitId || id === onlineSbpId)

        window.checkout.selectedValues.paymentSystem.id = payData ? payData.id : '';
        window.checkout.selectedValues.paymentSystem.url = payData ? payData.url : '';
      }
      else
      {
        offlineItem.classList.remove('m-hidden');
      }
    }

    if (onlineSplitItem)
    {
      if (!isOnlineSplit)
      {
        onlineSplitItem.classList.add('m-hidden');

        const payData = window.checkout.paySystem.items.find(({id}) => id === onlineId || id === offlineId || id === onlineSbpId)

        window.checkout.selectedValues.paymentSystem.id = payData ? payData.id : '';
        window.checkout.selectedValues.paymentSystem.url = payData ? payData.url : '';
      }
      else
      {
        onlineSplitItem.classList.remove('m-hidden');
      }
    }

    if (onlineSbpItem)
    {
      if (!isOnlineSbp)
      {
        onlineSbpItem.classList.add('m-hidden');

        const payData = window.checkout.paySystem.items.find(({id}) => id === onlineId || id === offlineId || id === onlineSplitId)

        window.checkout.selectedValues.paymentSystem.id = payData ? payData.id : '';
        window.checkout.selectedValues.paymentSystem.url = payData ? payData.url : '';
      }
      else
      {
        onlineSbpItem.classList.remove('m-hidden');
      }
    }
  }

  function checkoutPreloader(isShow = true, isShowSkeleton = false) {
    const checkoutForm = document.querySelector('.ds-checkout-form');
    const checkoutAddressSelect = checkoutForm.querySelector('#js-select-open');
    const checkoutDeliveries = document.querySelector('.ds-ch-radio-deliveries');
    const checkoutPayment = document.querySelector("[data-content='checkout-payment']");
    const checkoutSubmit = document.querySelector(".ds-checkout-submit");
    const allInputs = checkoutForm.querySelectorAll('.input, .radio');

    if (isShow) {
      allInputs.forEach(input => {
        if (input) {
          input.setAttribute('disabled', '');
        }
      })

      if (isShowSkeleton) {
        if (checkoutDeliveries) {
          checkoutDeliveries.querySelectorAll('.ch-order-radio, .ch-order__address-wrap > [data-content="delivery-address"]').forEach(oRadio => {
            oRadio.classList.add('skeleton');
          });
        }
  
        if (checkoutPayment) {
          checkoutPayment.querySelectorAll('.ch-order-radio').forEach(oRadio => {
            oRadio.classList.add('skeleton');
          });
        }
      }

      if (checkoutSubmit) {
        checkoutSubmit.setAttribute('disabled', '');
      }

      if (checkoutAddressSelect) {
        checkoutAddressSelect.setAttribute('disabled', '');
      }
    } else {
      allInputs.forEach(input => {
        if (input) {
          input.removeAttribute('disabled');
        }
      })

      if (isShowSkeleton) {
        if (checkoutDeliveries) {
          checkoutDeliveries.querySelectorAll('.ch-order-radio, .ch-order__address-wrap > [data-content="delivery-address"]').forEach(oRadio => {
            oRadio.classList.remove('skeleton');
          });
        }

        if (checkoutPayment) {
          checkoutPayment.querySelectorAll('.ch-order-radio').forEach(oRadio => {
            oRadio.classList.remove('skeleton');
          });
        }
      }

      if (checkoutSubmit) {
        checkoutSubmit.removeAttribute('disabled');
      }

      if (checkoutAddressSelect) {
        checkoutAddressSelect.removeAttribute('disabled');
      }
    }
  }

  function updatePickupPopup(city, region, resetPoints, fias = '', zip = '') {
    sPickupsPaymentTypes = '';

    if (window.popupPickup && window.popupPickup.showPreloader) {
      window.popupPickup.showPreloader();
    }
    getAvailablePickupPoints(city, region, fias, zip)
       .then(result => {
         if (result.status === 'success' && result.data.success) {
           window.checkout.pickupPoints = result.data.pickupPoints;
           window.checkout.updatePickupPopup(result.data.pickupPoints);

           if (window.popupPickup && window.popupPickup.resetSearch) {
             window.popupPickup.resetSearch();
           }

           if (resetPoints) {
             window.checkout.resetPoint('pickup')
           }

           const oPickupDeliveryBtn = checkout.querySelector('input#ch-d-pickup[type="radio"]');
           sPickupsPaymentTypes = result.data.paymentTypes.join('_');

           if (oPickupDeliveryBtn && result.data.paymentTypes.length > 0) {
            oPickupDeliveryBtn.dataset.payment = sPickupsPaymentTypes;
           }
         }

         if (window.popupPickup && window.popupPickup.hidePreloader) {
           window.popupPickup.hidePreloader();
         }
       })
       .catch(error => {
         if (window.popupPickup && window.popupPickup.hidePreloader) {
           window.popupPickup.hidePreloader()
         }
       })

    function getAvailablePickupPoints(city, region, fias, zip) {
      if (window.BX) {
        return window.BX.ajax.runComponentAction('ega:order', 'getPickupPoints',
           {
             mode: 'class',
             data: {
               region,
               city,
               fias,
               zip
             },
           })
      } else {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve({
              status: 'success',
              data: {
                success: true,
                pickupPoints: [
                  {
                    id: '1003062711',
                    name: 'На Хорошёвском шоссе 27',
                    address: 'Хорошёвское ш., 27',
                    workTime: 'Пн-Пт 10:00-21:00',
                    coords: [55.77923865906552, 37.52356959153274],
                    priceFormatted: '300 ₽',
                    price: '300',
                    typeText: 'Пункт выдачи заказа',
                    providerKeyText: "СДЭК"
                  },
                  {
                    id: '1003062714',
                    name: 'ТРЦ "Мозаика"',
                    address: 'ул, 7-я Кожуховская ул., 9 ТРЦ "Мозаика"',
                    workTime: 'Пн-Пт 10:00-20:00, Сб-Вс 10:00-18:00',
                    coords: [55.716468154586934, 37.6731217461949],
                    priceFormatted: '300 ₽',
                    price: '300',
                    typeText: 'Пункт выдачи заказа',
                    providerKeyText: "СДЭК"
                  },
                  {
                    id: '1003062715',
                    name: 'ТРЦ Columbus',
                    address: 'Кировоградская ул., 13А ТРЦ Columbus',
                    workTime: 'Пн-Пт 10:00-20:00, Сб-Вс 10:00-18:00',
                    coords: [55.6116990331495, 37.60723530006875],
                    priceFormatted: '300 ₽',
                    price: '300',
                    typeText: 'Пункт выдачи заказа',
                    providerKeyText: "СДЭК"
                  }
                ]
              }
            });
          }, 2000)

        });
      }
    }
  }

  function updateShopsPopup(cityObject, resetPoints) {
    if (window.popupShop && window.popupShop.showPreloader) {
      window.popupShop.showPreloader();
    }
    
    getAvailableShopsPoints(cityObject)
       .then(result => {
         if (result.status === 'success' && result.data.success) {
           window.checkout.shopsPoints = result.data.shopsPoints;
           window.checkout.updateShopsPopup(result.data.shopsPoints);

           if (window.popupShop && window.popupShop.resetSearch) {
             window.popupShop.resetSearch();
           }

           if (resetPoints) {
             window.checkout.resetPoint('shop')
           }
         }
         if (window.popupShop && window.popupShop.hidePreloader) {
           window.popupShop.hidePreloader();
         }
       })
       .catch(error => {
         if (window.popupShop && window.popupShop.hidePreloader) {
           window.popupShop.hidePreloader();
         }
       })

    function getAvailableShopsPoints(cityObject) {
      let data = {};

      if (cityObject.id) {
        data = {
          id: cityObject.id
        }
      } else {
        data = {
          name: cityObject.name
        }
      }

      if (window.BX) {
        return window.BX.ajax.runComponentAction('ega:order', 'getShopPoints',
           {
             mode: 'class',
             data
           })
      } else {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve({
              status: 'success',
              data: {
                success: true,
                shopsPoints: [
                  {
                    id: '2003062711',
                    name: 'ТРЦ "Гранд Юг"',
                    address: 'ул. Кировоградская, д. 15, ТРЦ «Гранд Юг»',
                    workTime: 'Пн-Вс 10:00-21:00',
                    coords: [56.010233, 37.859322]
                  },
                  {
                    id: '2003062714',
                    name: 'ТРЦ "Мозаика"',
                    address: 'ул, 7-я Кожуховская ул., 9 ТРЦ "Мозаика"',
                    workTime: 'Пн-Пт 10:00-20:00, Сб-Вс 10:00-18:00',
                    coords: [55.716468154586934, 37.6731217461949]
                  },
                  {
                    id: '2003062715',
                    name: 'ТРЦ Columbus',
                    address: 'Кировоградская ул., 13А ТРЦ Columbus',
                    workTime: 'Пн-Пт 10:00-20:00, Сб-Вс 10:00-18:00',
                    coords: [55.6116990331495, 37.60723530006875]
                  }
                ]
              }
            });
          }, 2000)

        });
      }
    }
  }

  function updateCheckoutWindowData(country, region, cityObject, resetPoints = true, resetAddress = true) {
    checkoutPreloader();
    const newCity = (cityObject && cityObject.name !== window.checkout.address.default.city && cityObject.name !== window.checkout.city.checked);
    
    if (country && country !== window.checkout.address.default.country) {
      const newCountry = window.checkout.countries[country];
      
      if (newCountry) {
        window.checkout.updateCountry(newCountry, region, cityObject.name)
      }
    }

    getAvailableDeliveries(region, cityObject.name, cityObject.fias, cityObject.zip)
       .then(result => {

         if (result.status === 'success' && result.data.success) {
           if (result.data) {
             if (result.data.deliveries) {
               setAvailableDeliveries(result.data.deliveries);
               window.checkout.delivery.items = result.data.deliveries;
             }

             if (result.data.checked) {
               window.checkout.deleteDeliveryError();
               window.checkout.delivery.checked = result.data.checked;
             }
           }
         }

         checkoutPreloader(false);
         checkAvailableDeliveries(result.data.deliveries, newCity);
         window.citiesPopup.endLoadingData();
       })
       .catch(error => {
         console.group('getAvailableDeliveries');
         console.error(error);
         console.groupEnd();
         checkoutPreloader(false);
         window.citiesPopup.endLoadingData();
       })

    updatePickupPopup(cityObject.name, region, resetPoints, cityObject.fias, cityObject.zip)
    updateShopsPopup(cityObject, resetPoints)

    if (window.dadata.resetAddressInputs && resetAddress) {
      window.dadata.resetAddressInputs();
    }

    return new Promise(resolve => {
      resolve()
    })
  }

  function updateCheckoutDeliveryPrice(price) {
    const priceNode = document.getElementById('deliveryPrice');
    priceNode.innerHTML = price;
    window.checkout.selectedValues.delivery.price = price;
    updateCheckoutTotalPrice();
  }

  function updateCheckoutTotalPrice() {
    const sumPrice = (document.getElementById('checkoutSum') ? document.getElementById('checkoutSum').textContent : '');
    const salePrice = (document.getElementById('checkoutSale') ? document.getElementById('checkoutSale').textContent : '');
    const bonusPrice = (document.getElementById('checkoutBonus') ? document.getElementById('checkoutBonus').textContent : '');
    const deliveryPrice = (document.getElementById('deliveryPrice') ? document.getElementById('deliveryPrice').textContent : '');
    const promocodePrice = (document.getElementById('promocodeDiscount') ? document.getElementById('promocodeDiscount').textContent : '');
    const totalPrice = (document.getElementById('checkoutTotal'));

    let sum = (getNumber(sumPrice) - getNumber(salePrice) - getNumber(bonusPrice) - getNumber(promocodePrice));

    if (deliveryPrice.toLowerCase() !== 'бесплатно') {
      sum = (sum + getNumber(deliveryPrice));
    }

    totalPrice.innerHTML = moneyFormat(sum) + ' ₽';
    window.checkout.selectedValues.paymentSystem.sum = moneyFormat(sum) + ' ₽';

    function getNumber(str) {
      const number = parseFloat(str.replace(/[^0-9.]/g, ''));

      return (number ? number : 0);
    }

    function moneyFormat(price) {
      const value = price.toString();
      let slicedValue = [];
      let moneyValue = '';
      const counter = Math.ceil(value.length / 3);

      for (let i = counter; i > 0; i--) {
        let start = value.length - 3 * i;
        let end = value.length - 3 * i + 3;
        
        if (start < 0) {
          start = 0
        }

        slicedValue.push(value.slice(start, end));
      }

      for (let i = 0; i < counter; i++) {
        moneyValue += i === counter - 1 ? slicedValue[i] : slicedValue[i] + ' ';
      }

      return moneyValue
    }
  }

  function updateDeliveryPrice(type, price) {
    const selectedRadioItem = document.querySelector(`input.radio#ch-d-${type}`);

    if (selectedRadioItem) {
      const priceNode = selectedRadioItem.parentElement.querySelector('.ds-delivery-price');

      if (priceNode) {
        priceNode.innerHTML = price;
        updateCheckoutDeliveryPrice(price);
      }
    }
  }

  function checkAvailableDeliveries(deliveriesList, newCity = false) {
    var isDeliveryNotAvailable = false;
    var paymentItems = document.querySelector('[data-content=\'checkout-payment\']');
    var deliveryNotAvailableText = document.querySelector('.delivery-not-available');
    var orderAddress = document.querySelector('.ch-order__address');
    var checkoutSubmit = document.querySelector(".ds-checkout-submit");

    for (const [type, delivery] of Object.entries(deliveriesList)) {
      if (delivery.available) {
        isDeliveryNotAvailable = true;
      }
    }

    if (!isDeliveryNotAvailable) {
      checkoutSubmit.setAttribute('disabled', '');
      paymentItems.style.display = 'none';
      orderAddress.style.display = 'none';
      deliveryNotAvailableText.style.display = '';
    } else {
      checkoutSubmit.removeAttribute('disabled');
      paymentItems.style.display = '';
      orderAddress.style.display = '';
      deliveryNotAvailableText.style.display = 'none';
    }

    if (newCity)
      setFirstDeliveryOrPayment('delivery');
  }

  function updateCheckoutOnProfileSelect(country, region, cityObject, resetPoints = true, resetAddress = true) {
    checkoutPreloader();

    if (country && country !== window.checkout.address.default.country) {
      const newCountry = window.checkout.countries[country];

      if (newCountry) {
        window.checkout.updateCountry(newCountry, region, cityObject.name)
      }
    }

    return new Promise(function (resolve, reject) {
      getAvailableDeliveries(region, cityObject.name, cityObject.fias, cityObject.zip)
          .then(result => {
            if (result.status === 'success' && result.data.success) {
              if (result.data) {
                if (result.data.deliveries) {
                  setAvailableDeliveries(result.data.deliveries);
                  window.checkout.delivery.items = result.data.deliveries;
                }

                if (result.data.checked) {
                  window.checkout.deleteDeliveryError();
                  window.checkout.delivery.checked = result.data.checked;
                }
              }
            }

            checkAvailableDeliveries(result.data.deliveries);
            window.citiesPopup.endLoadingData();
          })
          .catch(error => {
            console.group('getAvailableDeliveries');
            console.error(error);
            console.groupEnd();
            checkoutPreloader(false);
            window.citiesPopup.endLoadingData();
          })

      updateShopsPopupOnProfileSelect(cityObject, resetPoints)
      updatePickupPopupOnProfileSelect(cityObject.name, region, resetPoints, cityObject.fias, cityObject.zip)
          .then(() => resolve())
          .catch(error => reject(error));

      if (window.dadata.resetAddressInputs && resetAddress) {
        window.dadata.resetAddressInputs();
      }
    });
  }

  function updatePickupPopupOnProfileSelect(city, region, resetPoints, fias = '', zip = '') {
    return new Promise(function (resolve, reject) {
      if (window.popupPickup && window.popupPickup.showPreloader) {
        window.popupPickup.showPreloader();
      }
      getAvailablePickupPoints(city, region, fias, zip)
          .then(result => {
            if (result.status === 'success' && result.data.success) {
              window.checkout.pickupPoints = result.data.pickupPoints;
              window.checkout.updatePickupPopup(result.data.pickupPoints);

              if (window.popupPickup && window.popupPickup.resetSearch) {
                window.popupPickup.resetSearch();
              }

              if (resetPoints) {
                window.checkout.resetPoint('pickup')
              }
            }

            if (window.popupPickup && window.popupPickup.hidePreloader) {
              window.popupPickup.hidePreloader();
            }

            resolve();
          })
          .catch(error => {
            if (window.popupPickup && window.popupPickup.hidePreloader) {
              window.popupPickup.hidePreloader()
            }
            reject(error);
          })

      function getAvailablePickupPoints(city, region, fias, zip) {
        if (window.BX) {
          return window.BX.ajax.runComponentAction('ega:order', 'getPickupPoints',
              {
                mode: 'class',
                data: {
                  region,
                  city,
                  fias,
                  zip
                },
              })
        } else {
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve({
                status: 'success',
                data: {
                  success: true,
                  pickupPoints: [
                    {
                      id: '1003062711',
                      name: 'На Хорошёвском шоссе 27',
                      address: 'Хорошёвское ш., 27',
                      workTime: 'Пн-Пт 10:00-21:00',
                      coords: [55.77923865906552, 37.52356959153274],
                      priceFormatted: '300 ₽',
                      price: '300',
                      typeText: 'Пункт выдачи заказа',
                      providerKeyText: "СДЭК"
                    },
                    {
                      id: '1003062714',
                      name: 'ТРЦ "Мозаика"',
                      address: 'ул, 7-я Кожуховская ул., 9 ТРЦ "Мозаика"',
                      workTime: 'Пн-Пт 10:00-20:00, Сб-Вс 10:00-18:00',
                      coords: [55.716468154586934, 37.6731217461949],
                      priceFormatted: '300 ₽',
                      price: '300',
                      typeText: 'Пункт выдачи заказа',
                      providerKeyText: "СДЭК"
                    },
                    {
                      id: '1003062715',
                      name: 'ТРЦ Columbus',
                      address: 'Кировоградская ул., 13А ТРЦ Columbus',
                      workTime: 'Пн-Пт 10:00-20:00, Сб-Вс 10:00-18:00',
                      coords: [55.6116990331495, 37.60723530006875],
                      priceFormatted: '300 ₽',
                      price: '300',
                      typeText: 'Пункт выдачи заказа',
                      providerKeyText: "СДЭК"
                    }
                  ]
                }
              });
            }, 2000)

          });
        }
      }
    });
  }

  function updateShopsPopupOnProfileSelect(cityObject, resetPoints) {
    return new Promise(function (resolve, reject) {
      if (window.popupShop && window.popupShop.showPreloader) {
        window.popupShop.showPreloader();
      }

      getAvailableShopsPoints(cityObject)
          .then(result => {
            if (result.status === 'success' && result.data.success) {
              window.checkout.shopsPoints = result.data.shopsPoints;
              window.checkout.updateShopsPopup(result.data.shopsPoints);

              if (window.popupShop && window.popupShop.resetSearch) {
                window.popupShop.resetSearch();
              }

              if (resetPoints) {
                window.checkout.resetPoint('shop')
              }
            }
            if (window.popupShop && window.popupShop.hidePreloader) {
              window.popupShop.hidePreloader();
            }

            resolve();
          })
          .catch(error => {
            if (window.popupShop && window.popupShop.hidePreloader) {
              window.popupShop.hidePreloader();
            }
            reject(error);
          })

      function getAvailableShopsPoints(cityObject) {
        let data = {};

        if (cityObject.id) {
          data = {
            id: cityObject.id
          }
        } else {
          data = {
            name: cityObject.name
          }
        }

        if (window.BX) {
          return window.BX.ajax.runComponentAction('ega:order', 'getShopPoints',
              {
                mode: 'class',
                data
              })
        } else {
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve({
                status: 'success',
                data: {
                  success: true,
                  shopsPoints: [
                    {
                      id: '2003062711',
                      name: 'ТРЦ "Гранд Юг"',
                      address: 'ул. Кировоградская, д. 15, ТРЦ «Гранд Юг»',
                      workTime: 'Пн-Вс 10:00-21:00',
                      coords: [56.010233, 37.859322]
                    },
                    {
                      id: '2003062714',
                      name: 'ТРЦ "Мозаика"',
                      address: 'ул, 7-я Кожуховская ул., 9 ТРЦ "Мозаика"',
                      workTime: 'Пн-Пт 10:00-20:00, Сб-Вс 10:00-18:00',
                      coords: [55.716468154586934, 37.6731217461949]
                    },
                    {
                      id: '2003062715',
                      name: 'ТРЦ Columbus',
                      address: 'Кировоградская ул., 13А ТРЦ Columbus',
                      workTime: 'Пн-Пт 10:00-20:00, Сб-Вс 10:00-18:00',
                      coords: [55.6116990331495, 37.60723530006875]
                    }
                  ]
                }
              });
            }, 2000)

          });
        }
      }
    });
  }

  function setFirstDeliveryOrPayment(sType) {
    let sWrapContentAttr = sType;

    if (sType === 'payment')
      sWrapContentAttr = 'checkout-payment';

    document.querySelectorAll('[data-content=\'' + sWrapContentAttr + '\'] li:not(.m-hidden) [name=\'' + sType + '\']')[0]?.click();
  }

  function resetNodeErrors(oNode) {
    const errorElements = oNode.querySelectorAll('.ch-order__error');

    errorElements.forEach(oError => {
      oError.remove();
    });
  }

  function getRecalculatedBasket(paymentId)
  {
    window.BX.ajax.runComponentAction('ega:basket', 'recalculateBasket',
        {
          mode: 'class',
          data: {
            paymentId,
          },
        })
        .then((oResponse) => {
          if(oResponse.data.success)
          {
            let oTotalBlock = $('.order-total'),
                oEarnedBonus = oTotalBlock.find('#earnedBonus'),
                oCheckoutSale = oTotalBlock.find('#checkoutSale'),
                oPromocodeDiscountBlock = oTotalBlock.find('#promocodeDiscountBlock'),
                oPromocodeDiscount = oTotalBlock.find('#promocodeDiscount'),
                oCheckoutBonusBlock = oTotalBlock.find('#checkoutBonusBlock'),
                oCheckoutBonus = oTotalBlock.find('#checkoutBonus');

            for(let oBasketItemXmlId of Object.keys(oResponse.data.arResult.BASKET_ITEMS))
            {
              $('.order-total__param[data-id="' + oResponse.data.arResult.BASKET_ITEMS[oBasketItemXmlId].ID + '"]')
                  .find('.order-total__price').html(oResponse.data.arResult.BASKET_ITEMS[oBasketItemXmlId].PRICE_TOTAL_FORMATED).css('display', '').end()
                  .find('.discountPrice').html(oResponse.data.arResult.BASKET_ITEMS[oBasketItemXmlId].DISCOUNT_PRICE_FORMATED);

              if(oResponse.data.arResult.BASKET_ITEMS[oBasketItemXmlId].PRICE_TOTAL == oResponse.data.arResult.BASKET_ITEMS[oBasketItemXmlId].DISCOUNT_PRICE)
                $('.order-total__param[data-id="' + oResponse.data.arResult.BASKET_ITEMS[oBasketItemXmlId].ID + '"]').find('.order-total__price').html('').css('display', 'none');;
            }

            oCheckoutSale.html((!empty(oResponse.data.arResult.DISCOUNT_PRICE) ? '- ' : '') + oResponse.data.arResult.DISCOUNT_PRICE_FORMATED);
            oEarnedBonus.html(!empty(oResponse.data.arResult.EARNED_BONUS) ? '+ ' + oResponse.data.arResult.EARNED_BONUS + ' ₽' : '0 ₽');

            if(!oResponse.data.arResult.COUPON.CAN_BE_USED)
            {
              oPromocodeDiscountBlock.hide();
              oPromocodeDiscount.html('0  ₽');
            }
            else
            {
              oPromocodeDiscount.html('- ' + oResponse.data.arResult.COUPON.DISCOUNT + ' ₽');
              oPromocodeDiscountBlock.show();
            }

            if(!empty(oResponse.data.arResult.POINTS))
            {
              if(oResponse.data.arResult.POINTS.CAN_BE_USED)
              {
                if (!empty(oResponse.data.arResult.POINTS.USED_POINTS_FORMATTED))
                {
                  oCheckoutBonusBlock.fadeIn();
                  oCheckoutBonus.html('- ' + oResponse.data.arResult.POINTS.USED_POINTS_FORMATTED + ' ₽');
                }
                else
                {
                  oCheckoutBonusBlock.fadeOut();
                }
              }
              else
              {
                oCheckoutBonusBlock.fadeOut();
                oCheckoutBonus.html(0);
              }
            }

            updateCheckoutTotalPrice();
          }
        })
  }
});
