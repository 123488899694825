import popupCities from "%modules%/popups/popup-cities/popup-cities";
import popupPickup from "%modules%/popups/popup-pickup/popup-pickup";
import popupShop from "%modules%/popups/popup-shop/popup-shop";
import popupStore from "%modules%/popups/popup-store/popup-store";
import popupFilter from "%modules%/popups/popup-filter/popup-filter";
import popupAdmission from "%modules%/popups/popup-admission/popup-admission";

document.addEventListener('DOMContentLoaded', () => {
  let debounceParams = {
    instance: null,
    timeout: 250
  }

  const debounce = (func) => {
    clearTimeout(debounceParams.instance);
    debounceParams.instance = setTimeout(
       () => {
         func();
       },
       debounceParams.timeout
    )
  }

  if (document.querySelector('.ds-popup-pickup')) {
    if (!window.popupPickup) {
      window.popupPickup = {};
    }
    popupPickup();
    setPopupEvents('pickup', true);
    popupFilter();

    if (window.checkout.pickupPoints) {
      setPointsList(window.checkout.pickupPoints, 'pickup')
    }

    const selectPickupBtn = document.querySelector('.ds-popup-pickup-select');
    selectPointInPopup('pickup', selectPickupBtn);

    window.popupPickup.resetSearch = () => resetSearch('pickup');

    window.checkout.updatePickupPopup = (points, onlyList = false) => updatePoints(points, 'pickup', onlyList)
  }

  if (document.querySelector('.ds-popup-shop')) {
    if (!window.popupShop) {
      window.popupShop = {};
    }
    popupShop();
    setPopupEvents('shop', true);

    if (window.checkout.shopsPoints) {
      setPointsList(window.checkout.shopsPoints, 'shop')
    }

    const selectShopBtn = document.querySelector('.ds-popup-shop-select');
    selectPointInPopup('shop', selectShopBtn);

    const shopsSearchInput = document.getElementById('popup-shop-address');
    shopsSearchInput.addEventListener('input', () => {
      debounce(() => {
        setSearchPointsList('shop', shopsSearchInput.value)
      })
    })

    window.popupShop.resetSearch = () => resetSearch('shop');

    window.checkout.updateShopsPopup = (points) => updatePoints(points, 'shop')
  }

  if (document.querySelector('.ds-popup-history-cart')) {
    setPopupEvents('history-cart');
  }

  if (document.querySelector('.ds-popup-select-city')) {
    setPopupEvents('cities');
  }

  if (document.querySelector('.ds-popup-cities')) {
    popupCities();
  }

  if (document.querySelector('.ds-popup-auth')) {
    setPopupEvents('auth');
  }

  if (document.querySelector('.ds-popup-register')) {
    setPopupEvents('register');
  }

  if (document.querySelector('.js-link-button')) {
    popupStore();
  }

  if (document.querySelector('.ds-popup-admission')) {
    setPopupEvents('admission');
    popupAdmission();
  }

  if (document.querySelector('.ds-popup-success')) {
    setPopupEvents('success');
  }

  if (document.querySelector('.ds-popup-maximum')) {
    setPopupEvents('maximum');
  }

  if (document.querySelector('.ds-popup-review')) {
    setPopupEvents('review');
  }

  if (document.querySelector('.ds-popup-thanks')) {
    setPopupEvents('thanks');
  }

  if (document.querySelector('.ds-popup-error')) {
    setPopupEvents('error');
  }
})

function selectPointInPopup(type, selectButton) {
  selectButton.addEventListener('click', () => {
    const pointId = selectButton.getAttribute('data-point-id');

    const deliveryContent = document.querySelector("[data-content='delivery']");
    const pointInfo = deliveryContent.querySelector(`[data-content='delivery-${type}']`);
    const pointTitle = pointInfo.querySelector(`[data-content='${type}-title']`);
    const pointAddress = pointInfo.querySelector(`[data-content='${type}-address']`);
    const pointProp = pointInfo.querySelector(`[data-content='${type}-prop']`);
    const paymentInput = document.querySelector("input#ch-d-".concat(type));
    const isAddressPage = document.querySelector('.addresses__edit');
    let point = null;

    if (type === 'pickup') {
      point = window.checkout.pickupPoints.find(({id}) => id == pointId);
      if (!isAddressPage) {
        paymentInput.setAttribute('data-payment', point.paymentType);
        let paymentsId = point.paymentType.split('_');
        checkPayment(paymentsId)
      }
    } else {
      point = window.checkout.shopsPoints.find(({id}) => id == pointId);
    }

    if (point) {
      window.checkout.setNewAddress();
      pointTitle.innerHTML = point.name;
      pointAddress.innerHTML = point.address;
      pointInfo.classList.remove('m-hidden');

      if (type === 'pickup') {
        pointProp.innerHTML = 'Примерка: ' + (point.tryOn ? 'Да' : 'Нет') + ', Частичный выкуп: ' + (point.partialReturn ? 'Да' : 'Нет');
        window.checkout.selectedPickup = point;
        window.checkout.selectedValues.delivery.pickupId = point.id;
      } else {
        window.checkout.selectedShop = point;
        window.checkout.selectedValues.delivery.shopId = point.id;
      }

      if (point.priceFormatted) {
        window.checkout.updateDeliveryPrice(type, point.priceFormatted);
      }

      const popup = document.querySelector(`.ds-popup-${type}`);
      popup.removeAttribute('data-open');

      setTimeout(() => {
        if (type === 'pickup') {
          window.popupPickup.hideDetailInfo();
        } else {
          window.popupShop.hideDetailInfo();
        }
      }, 250)

      resetSearch(type);

      if (type === 'pickup') {
        updatePoints(window.checkout.pickupPoints, 'pickup')
      } else {
        updatePoints(window.checkout.shopsPoints, 'shop')
      }

      const radioInput = deliveryContent.querySelector(`input#ch-d-${type}`);
      const buttonSelect = radioInput.parentElement.querySelector('button');
      buttonSelect.classList.add('m-hidden');

      document.querySelectorAll('[data-content=\'checkout-payment\'] li:not(.m-hidden) [name=\'payment\']')[0]?.click();
    }
  })
}

function searchPoints(type, query) {
  const list = type === 'pickup' ? window.checkout.pickupPoints : window.checkout.shopsPoints;
  let resultId = new Set();
  let result = [];
  const checkQuery = query.toLowerCase().split(' ');

  list.forEach(item => {

    for (const value of Object.values(item)) {
      if (typeof value === 'string') {
        const checkArray = value.toLowerCase().split(' ');
        let queryCounter = 0;

        checkQuery.forEach(queryItem => {
          checkArray.forEach(checkItem => {
            if (checkItem.includes(queryItem)) {
              queryCounter++;
            }
          })
        })

        if (queryCounter === checkQuery.length) {
          resultId.add(item.id);
        }
      }
    }
  })

  resultId.forEach(pointId => {
    const item = list.find(({id}) => id === pointId)
    if (item) {
      result.push(item)
    }
  })
  return result;
}

function addPointToList(type, point) {
  const list = document.querySelector(`.ds-popup-${type}-list`);
  const item = document.createElement('button');
  const pointDistance = point.distance || '';
  item.classList.add('popup-pickup__item', `ds-popup-${type}-point`);
  item.type = 'button';
  item.innerHTML += `<p class="popup-pickup__name">${point.name}</p>`

  if (type === 'pickup') {
    const typeText = (point.providerKeyText ? point.providerKeyText : '') + (point.typeText ? ` | ${point.typeText}` : '') + (point.priceFormatted ? ` | ${point.priceFormatted}` : '');
    item.innerHTML += `<p class="popup-pickup__type">${typeText}</p>`;
    item.setAttribute('data-id', point.id);
  } else {
    item.setAttribute('data-id', point.storeXmlId);
  }

 let itemInfo = '<div class="popup-pickup__info">';

  if (pointDistance)
    itemInfo += `<p class="popup-pickup__text m-way">До пункта выдачи ${pointDistance}</p>`;

  itemInfo += `<p class="popup-pickup__text m-address">Адрес: ${point.address}</p>`;
  itemInfo += `<p class="popup-pickup__text m-work">Режим работы: ${point.workTime}</p>`;
  itemInfo += `</div>`;

  item.innerHTML += itemInfo;

  list.appendChild(item);
}

function setSearchPointsList(type, query) {
  const points = searchPoints(type, query);
  updatePoints(points, type)
}

function updatePoints(points, type, onlyList = false) {
  setPointsList(points, type);

  if (onlyList)
    return;

  if (type === 'pickup' ) {
    if (window.popupPickup && window.window.popupPickup.updateMapPoints) {
      window.popupPickup.updateMapPoints(points)
    }
  } else {
    if (window.popupShop && window.popupShop.updateMapPoints) {
      window.popupShop.updateMapPoints(points)
    }
  }
}

function setPointsList(points, type) {
  const list = document.querySelector(`.ds-popup-${type}-list`);
  list.innerHTML = ''
  if (points && points.length) {
    points.forEach(point => {
      addPointToList(type, point)
    })

    const popup = document.querySelector(`.ds-popup-${type}`);
    let selectedPointData = null;
    if (type === 'pickup') {
      selectedPointData = window.checkout.selectedPickup;
    } else {
      selectedPointData = window.checkout.selectedShop;
    }
    if (selectedPointData && selectedPointData.id) {
      const pointId = selectedPointData.id;
      const selectedPoint = popup.querySelector(`.ds-popup-${type}-point[data-id='${pointId}']`);
      popup.querySelectorAll(`.ds-popup-${type}-point`).forEach(pointItem => {
        pointItem.removeAttribute('data-active');
      })
      if (selectedPoint) {
        selectedPoint.setAttribute('data-active', '');
      }
    }
  } else {
    list.innerHTML = '<span>Ничего не найдено</span>'
  }
}

function resetSearch(type) {
  const searchInput = document.getElementById(`popup-${type}-address`);
  searchInput.value = '';
}


window.popups = {};
window.popups.list = [];
window.popups.close = (popupName) => {
  const popup = window.popups.list.find(({id}) => id === popupName)
  if (popup) {
    popup.nodeItem.removeAttribute('data-open');
  }
}
window.popups.open = (popupName) => {
  const popup = window.popups.list.find(({id}) => id === popupName)
  if (popup) {
    popup.nodeItem.setAttribute('data-open', '')
  }
}

function setPopupEvents(className, isMap = false) {
  const popupOpen = document.querySelectorAll(`.ds-popup-${className}-open`);
  const popupClose = document.querySelectorAll(`.ds-popup-${className}-close`);
  const popup = document.querySelector(`.ds-popup-${className}`);
  window.popups.list.push({id: className, nodeItem: popup});

  popupOpen.forEach(openButton => {
    openButton.addEventListener('click', () => {
        popup.setAttribute('data-open', '')
    });
  });
  
  popupClose.forEach(close => {
    close.addEventListener('click', () => {
      popup.removeAttribute('data-open');
      if (className === 'pickup') {
        window.popupPickup.hideDetailInfo();
      }
      if (className === 'shop') {
        window.popupShop.hideDetailInfo();
      }
    })
  })

  popup.addEventListener('click', ({target}) => {
    if (!(target.closest(`.ds-popup-${className}-body`) || target.nodeName.toLowerCase() === 'ymaps')) {
      popup.removeAttribute('data-open');
      if (className === 'pickup') {
        setTimeout(() => {
          window.popupPickup.hideDetailInfo();
        }, 250)
      }
      if (className === 'shop') {
        setTimeout(() => {
          window.popupShop.hideDetailInfo();
        }, 250)
      }
    }
  })

  if (isMap) {
    const popupMain = document.querySelector(`.ds-popup-${className}-main`);
    const popupMap = document.querySelector(`.ds-popup-${className}-map`);
    const popupBtnList = document.querySelector(`.ds-popup-${className}-btn-list`);
    const popupBtnMap = document.querySelector(`.ds-popup-${className}-btn-map`);

    if (popupBtnList) {
      popupBtnList.addEventListener('click', () => {
        popupMap.removeAttribute('data-active');
        popupMain.setAttribute('data-active', '');
        popupBtnList.classList.add('active');
        popupBtnMap.classList.remove('active');
      })
    }

    if (popupBtnMap) {
      popupBtnMap.addEventListener('click', () => {
        popupMap.setAttribute('data-active', '');
        popupMain.removeAttribute('data-active');
        popupBtnList.classList.remove('active');
        popupBtnMap.classList.add('active');
      })
    }
  }
}

function checkPayment(ids) {
  const paymentItems = document.querySelector('[data-content=\'checkout-payment\']');

  const onlineItemInput = paymentItems.querySelector('input#ch-p-online');
  const onlineItem = onlineItemInput ? onlineItemInput.parentElement : null;
  const onlineId = onlineItem ? onlineItem.getAttribute('data-id') : null;
  const isOnline = onlineId && ids.includes(onlineId);
  if (onlineItemInput) onlineItemInput.checked = false;

  const offlineItemInput = paymentItems.querySelector('input#ch-p-offline');
  const offlineItem = offlineItemInput ? offlineItemInput.parentElement : null;
  const offlineId = offlineItem ? offlineItem.getAttribute('data-id') : null;
  const isOffline = offlineId && ids.includes(offlineId);
  if (offlineItemInput) offlineItemInput.checked = false;

  const onlineSplitItemInput = paymentItems.querySelector('input#ch-p-onlineSplit');
  const onlineSplitItem = onlineSplitItemInput ? onlineSplitItemInput.parentElement : null;
  const onlineSplitId = onlineSplitItem ? onlineSplitItem.getAttribute('data-id') : null;
  const isOnlineSplit = onlineSplitId && ids.includes(onlineSplitId);
  if (onlineSplitItemInput) onlineSplitItemInput.checked = false;

  const onlineSbpItemInput = paymentItems.querySelector('input#ch-p-onlineSbp');
  const onlineSbpItem = onlineSbpItemInput ? onlineSbpItemInput.parentElement : null;
  const onlineSbpId = onlineSbpItem ? onlineSbpItem.getAttribute('data-id') : null;
  const isOnlineSbp = onlineSbpId && ids.includes(onlineSbpId);
  if (onlineSbpItemInput) onlineSbpItemInput.checked = false;

  if (onlineItem)
  {
    if (!isOnline)
    {
      onlineItem.classList.add('m-hidden');

      const payData = window.checkout.paySystem.items.find(({id}) => id === offlineId || id === onlineSplitId || id === onlineSbpId)

      window.checkout.selectedValues.paymentSystem.id = payData ? payData.id : '';
      window.checkout.selectedValues.paymentSystem.url = payData ? payData.url : '';
    }
    else
    {
      onlineItem.classList.remove('m-hidden');
    }
  }

  if (offlineItem)
  {
    if (!isOffline)
    {
      offlineItem.classList.add('m-hidden');

      const payData = window.checkout.paySystem.items.find(({id}) => id === onlineId || id === onlineSplitId || id === onlineSbpId)

      window.checkout.selectedValues.paymentSystem.id = payData ? payData.id : '';
      window.checkout.selectedValues.paymentSystem.url = payData ? payData.url : '';
    }
    else
    {
      offlineItem.classList.remove('m-hidden');
    }
  }

  if (onlineSplitItem)
  {
    if (!isOnlineSplit)
    {
      onlineSplitItem.classList.add('m-hidden');

      const payData = window.checkout.paySystem.items.find(({id}) => id === onlineId || id === offlineId || id === onlineSbpId)

      window.checkout.selectedValues.paymentSystem.id = payData ? payData.id : '';
      window.checkout.selectedValues.paymentSystem.url = payData ? payData.url : '';
    }
    else
    {
      onlineSplitItem.classList.remove('m-hidden');
    }
  }

  if (onlineSbpItem)
  {
    if (!isOnlineSbp)
    {
      onlineSbpItem.classList.add('m-hidden');

      const payData = window.checkout.paySystem.items.find(({id}) => id === onlineId || id === offlineId || id === onlineSplitId)

      window.checkout.selectedValues.paymentSystem.id = payData ? payData.id : '';
      window.checkout.selectedValues.paymentSystem.url = payData ? payData.url : '';
    }
    else
    {
      onlineSbpItem.classList.remove('m-hidden');
    }
  }
}