//import ZooMove from 'zoomove/dist/zoomove.min'
//import StickySidebar from 'sticky-sidebar';
import $ from "jquery";
import Swiper from 'swiper';

window.$ = $;


$(document).ready(function () {

    if (document.documentElement.clientWidth <= 575) {


        let itemsSliders = document.getElementsByClassName('item-detail__left');

        Array.from(itemsSliders).forEach(function (slider) {

            //  console.log(slider.parentElement.getElementsByClassName('custom-slider__arrow--next')[0])

            new Swiper(slider, {
                loop: true,
                //effect: 'fade',
                speed: 800,
                //  fadeEffect: {crossFade: true},
                spaceBetween: 0,
                slidesPerView: 1,
                // autoplay: {
                //     delay: 7000,
                //     disableOnInteraction: false
                // },
                navigation: {
                    nextEl: slider.parentElement.getElementsByClassName('custom-slider__arrow--next')[0],
                    prevEl: slider.parentElement.getElementsByClassName('custom-slider__arrow--prev')[0]
                },
                pagination: false,
                breakpoints: {}
            });
        })
    }


    const sidebar = document.querySelector('#sidebar');


    // new StickySidebar(sidebar, {
    //     topSpacing: 0,
    //     bottomSpacing: 0,
    //     containerSelector: '.item-detail',
    //     innerWrapperSelector: '.item-detail__descr'
    // });


    if (sidebar) {

        if (document.documentElement.clientWidth >= 1025) {


            /*
        var stickySidebar = new StickySidebar(sidebar, {
            topSpacing: 80,
            bottomSpacing: 0,
            containerSelector: '#container',
            innerWrapperSelector: '#sidebar__inner'
        });

            */
            $(function () {
                const $sidebar = $('#sidebar__inner');
                var width = $sidebar.width();
                var left = $sidebar.position().left;
                document.documentElement.style.setProperty('--left', `${left}px`);
                document.documentElement.style.setProperty('--sidebar', `${width}px`);


                $(window).resize(function () {

                    document.documentElement.style.removeProperty('--sidebar');
                    document.documentElement.style.removeProperty('--left');
                    document.documentElement.style.setProperty('--sidebar', `${$('#sidebar__inner').width()}px`);
                    document.documentElement.style.setProperty('--left', `${$('#sidebar__inner').position().left}px`);
                });


                var top = $sidebar.offset().top - parseFloat($sidebar.css('marginTop').replace(/auto/, 0));
                var footTop = $('.def-section--big.hide-mob').offset().top - parseFloat($('.def-section--big.hide-mob').css('marginTop').replace(/auto/, 0));

                var maxY = footTop - $sidebar.outerHeight();

                $(window).scroll(function () {
                    var y = $(this).scrollTop() - 55;
                    if (y >= top - $('.header').height()) {
                        if (y < maxY - 150) {
                            $sidebar.addClass('fixed').removeAttr('style').removeClass('absolute');
                        } else {
                            $sidebar.removeClass('fixed').css({
                                position: 'absolute',
                                top: (maxY - top + 241) + 'px'
                            }).addClass('absolute');
                        }
                    } else {
                        $sidebar.removeClass('fixed').removeClass('absolute');
                    }
                });
            });
            /**/
        }

    }


    // Fancybox.bind("[data-fancybox1]", {
    //     infinite: false,
    //     groupAll: true,
    //     mainClass: 'fancy-custom'
    //
    // });

    /**
     * увеличенный просмотр: слайдеры
     * **/
    const itemZoom = document.querySelector('.ds-item-zoom-main');
    const itemZoomTabs = document.querySelector('.ds-item-zoom-tabs');

    const itemSlider = document.querySelector('.ds-item-main-slider');
    const itemZoomClose = document.querySelector('.ds-item-zoom-close');
    const itemZoomPopup = document.querySelector('.ds-item-zoom');

    const zoomSlider = new Swiper(itemZoom, {
        slidesPerView: 1,
        slidesPerGroup: 1,
        loop: false,
        allowTouchMove: false,
        effect: 'fade',
        navigation: {
            nextEl: '.ds-item-zoom-next',
            prevEl: '.ds-item-zoom-prev'
        }
    })

    const zoomTabsSlider = new Swiper(itemZoomTabs, {
        slidesPerView: 4,
        slidesPerGroup: 1,
        spaceBetween: 16,
        direction: 'vertical',
        loop: false,
        allowTouchMove: true,
        slideToClickedSlide: true

    })

    zoomTabsSlider.on('click', ({target}) => {
        const slide = target.closest('.ds-item-zoom-tab');
        if (slide) {
            const slideIndex = Number(slide.getAttribute('data-index'));
            zoomSlider.slideTo(slideIndex, 400);

            itemZoomTabs.querySelectorAll('.ds-item-zoom-tab').forEach(item => {
                item.removeAttribute('data-active');
            })

            slide.setAttribute('data-active', '');
        }
    })

    zoomSlider.on('slideChange', () => {
        const slideIndex = zoomSlider.realIndex;
        zoomTabsSlider.slideTo(slideIndex, 400);

        itemZoomTabs.querySelectorAll('.ds-item-zoom-tab').forEach(item => {
            item.removeAttribute('data-active');
        });

        const tabSlide = itemZoomTabs.querySelector(`.ds-item-zoom-tab[data-index='${slideIndex}']`)
        tabSlide.setAttribute('data-active', '')
    })

    /**
     * увеличенный просмотр: зум
     * **/
    if (itemZoom) {
        itemZoom.addEventListener('mousemove', (e) => {
            const el = e.target.closest('.ds-item-zoom-image');
            const elTop = el.scrollHeight / 4;
            const elLeft = el.scrollWidth / 4;
            el.setAttribute('data-zoomed', '');
            let curTop = window.innerHeight / 2 - e.clientY;
            let curLeft = window.innerWidth / 2 - e.clientX;

            curTop = Math.abs(curTop) > elTop ? Math.sign(curTop) * elTop : curTop
            curLeft = Math.abs(curLeft) > elLeft ? Math.sign(curLeft) * elLeft : curLeft
            el.style.transform = `scale(2) translateX(${curLeft}px) translateY(${curTop}px)`;
        })

        itemZoom.addEventListener('mouseout', (e) => {
            const el = e.target.closest('.ds-item-zoom-image');
            el.removeAttribute('data-zoomed');
            el.style.transform = '';
        })
    }

    /**
     * открытие закрытие увеличенного просмотра
     * **/
    if (itemSlider) {
        itemSlider.addEventListener('click', (e) => {
            const image = e.target.closest('.ds-item-main-image');
            if (image) {
                e.preventDefault();
                const index = Number(image.getAttribute('data-index'));
                zoomSlider.slideTo(index, 400);

                if (index === 0) {
                    const firstTab = itemZoomTabs.querySelector('.ds-item-zoom-tab');
                    firstTab.setAttribute('data-active', '');
                }

                itemZoomPopup.setAttribute('data-open', '')
            }
        })
    }

    if (itemZoomClose) {
        itemZoomClose.addEventListener('click', () => {
            itemZoomPopup.removeAttribute('data-open');
        })
    }

    document.addEventListener('keydown', (e) => {
        if (e.code === 'Escape' && itemZoomPopup) {
            itemZoomPopup.removeAttribute('data-open');
            closeZoomedEl();
        }
    })

    function closeZoomedEl() {
        const zoomedEl = itemZoom.querySelector('.ds-item-zoom-image[data-zoomed]')
        if (zoomedEl) {
            zoomedEl.removeAttribute('data-zoomed');
            zoomedEl.style.transform = '';
        }
    }
});







