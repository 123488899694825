export default () => {
    const oPopupFilter = document.querySelector('.popup-filter');

    if (!oPopupFilter)
        return;

    const arPopupFilterBtns = oPopupFilter.querySelectorAll('.popup-filter__variant');
    const oPopupFilterBottom = oPopupFilter.querySelector('.popup-filter__bottom');
    const oPopupFilterClearBtn = oPopupFilterBottom.querySelector('.popup-filter__clear');

    if (arPopupFilterBtns.length > 0)
        arPopupFilterBtns.forEach(oFilterBtn => {
            oFilterBtn.addEventListener('click', setFilter)
        });

    oPopupFilterClearBtn?.addEventListener('click', clearFilter);

    function setFilter({ target: oTarget }) {
        const { dataset: { value: sFilterValue } } = oTarget;

        let sBottomBlockToggleType = 'hide',
            iFilterCount = null,
            arFilteredPickups = [],
            arFilterValues = window.checkout.pickupPopupFilter || [];

        if (oTarget.classList.contains('selected')) {
            let iValuePos = arFilterValues.findIndex(sValue => sValue === sFilterValue);

            arFilterValues.splice(iValuePos, 1);
            oTarget.classList.remove('selected');
        } else {
            arFilterValues.push(sFilterValue);
            oTarget.classList.add('selected');
        }

        if (arFilterValues.length > 0) {
            sBottomBlockToggleType = 'show';

            arFilteredPickups = window.checkout.pickupPoints.filter((oPickupItem) => {
                for (let i = 0; i < arFilterValues.length; i++) {
                    if (oPickupItem[arFilterValues[i]])
                        return true;
                }

                return false;
            });

            iFilterCount = arFilteredPickups.length;

            window.checkout.updatePickupPopup(arFilteredPickups);
        } else {
            clearFilter();
        }

        toggleBottomBlock(sBottomBlockToggleType, iFilterCount);
        window.checkout.pickupPopupFilter = arFilterValues;
    }

    function clearFilter() {
        arPopupFilterBtns.forEach(oFilterBtn => {
            oFilterBtn.classList.remove('selected');
        });

        toggleBottomBlock('hide');
        window.checkout.pickupPopupFilter = [];
        window.checkout.updatePickupPopup(window.checkout.pickupPoints);
    }

    function toggleBottomBlock(sType, iFilterCount = 0) {
        const oFilterCountSpan = oPopupFilterBottom.querySelector('.popup-filter__count--filter');
        const oTotalCountSpan = oPopupFilterBottom.querySelector('.popup-filter__count--total');
        const iTotalCount = window.checkout.pickupPoints.length;

        switch (sType) {
            case 'show':
                oFilterCountSpan.innerText = iFilterCount;
                oTotalCountSpan.innerText = iTotalCount;

                oPopupFilterBottom.classList.add('show');
                break;

            case 'hide':
                oPopupFilterBottom.classList.remove('show');
                break;

            default:
                break;
        }
    }
};